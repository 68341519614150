import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';

class About extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
   
    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
       
    }

    render() {
        console.log(this.state.loginError)
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section className="hero-wrap hero-wrap-2 js-fullheight" id="backgroundSectionImg" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start" style={{height:'395px'}}>
                    <div className="col-md-9 ftco-animate pb-5 fadeInUp ftco-animated">
                    <p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>About us <i className="ion-ios-arrow-forward"></i></span></p>
                    <h1 className="mb-3 bread">About Us</h1>
                    </div>
                    </div>
                    </div>
                </section>


                <section className="ftco-section ftco-no-pt ftco-no-pb mt-5 mb-5">
                    <div className="container">
                        <div className="row no-gutters">
                        <div className="col-md-6 p-md-5 img img-2 d-flex justify-content-center align-items-center" style={{ backgroundImage: 'url(' + "uploads/cars/car-2.jpg" + ')'}}>
                        </div>
                        <div className="col-md-6 wrap-about py-md-5 ftco-animate fadeInUp ftco-animated">
                        <div className="heading-section mb-5 pl-md-5">
                        <span className="subheading">About us</span>
                        <h2 className="mb-4">Choose A Perfect Car</h2>
                        <p>JPL Elite creates an experience to serve you better. We understand that traveling, visiting, or just needing to rent a car can be a cumbersome task, but it shouldn't be! We intend to make your rental a smooth transaction from start to finish. We pride ourselves on creating custom quick experiences for our customers that leave a lasting impression. </p>
                        <p>No having to wait in long lines just to get the desk and be told that they don't have anything available. We bring guaranteed service directly to you. A better way to rent, travel, and save.</p>
                        <p><a href="#backgroundSectionImg" className="btn btn-primary">Book a Car</a></p>
                        </div>
                        </div>
                        </div>
                    </div>
                </section>
            

                {/* Service Section */}
                <section className="ftco-section services-section ftco-no-pt ftco-no-pb mb-5 mt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 heading-section text-center ftco-animate mb-5 fadeInUp ftco-animated">
                                <span className="subheading">Our Services</span>
                                <h2 className="mb-2">Our Services</h2>
                            </div>
                        </div>
                        <div className="row d-flex">
                            <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                <div className="media block-6 services">
                                    <div className="media-body py-md-4">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div className="icon"><SupportAgentIcon className="homeServiceIcons" /></div>
                                            <h3 className="heading mb-0 pl-3">24/7 Car Support</h3>
                                        </div>
                                        <p>A small river named Duden flows by their place and supplies it with you</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                <div className="media block-6 services">
                                    <div className="media-body py-md-4">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div className="icon"><MapIcon className="homeServiceIcons" /></div>
                                            <h3 className="heading mb-0 pl-3">Lots of location</h3>
                                        </div>
                                        <p>A small river named Duden flows by their place and supplies it with you</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                <div className="media block-6 services">
                                    <div className="media-body py-md-4">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div className="icon"><BookOnlineIcon className="homeServiceIcons" /></div>
                                            <h3 className="heading mb-0 pl-3">Reservation</h3>
                                        </div>
                                        <p>A small river named Duden flows by their place and supplies it with you</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                <div className="media block-6 services">
                                    <div className="media-body py-md-4">
                                        <div className="d-flex mb-3 align-items-center">
                                            <div className="icon"><CarRentalIcon className="homeServiceIcons" /></div>
                                            <h3 className="heading mb-0 pl-3">Rental Cars</h3>
                                        </div>
                                        <p>A small river named Duden flows by their place and supplies it with you</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <section className="ftco-section services-section img mt-5" style={{ backgroundImage: 'url(' + "uploads/cars/car1.jpg" + ')'}}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-7 text-center heading-section heading-section-white ftco-animate fadeInUp ftco-animated">
                                <span className="subheading">Work flow</span>
                                <h2 className="mb-3">How it works</h2>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div className="media block-6 services services-2">
                        <div className="media-body py-md-4 text-center">
                        <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
                        <h3>Pick Destination</h3>
                        <p>A small river named Duden flows by their place and supplies it with you</p>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div className="media block-6 services services-2">
                        <div className="media-body py-md-4 text-center">
                        <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-select"></span></div>
                        <h3>Select Term</h3>
                        <p>A small river named Duden flows by their place and supplies it with you</p>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div className="media block-6 services services-2">
                        <div className="media-body py-md-4 text-center">
                        <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-rent"></span></div>
                        <h3>Choose A Car</h3>
                        <p>A small river named Duden flows by their place and supplies it with you</p>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-3 d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                        <div className="media block-6 services services-2">
                        <div className="media-body py-md-4 text-center">
                        <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-review"></span></div>
                        <h3>Enjoy The Ride</h3>
                        <p>A small river named Duden flows by their place and supplies it with you</p>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </section>



               
                
            </React.Fragment>
        );
    }

}

export default About;