import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";

class HomeNew extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
   
    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
       
    }

    render() {
        console.log(this.state.loginError)
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div id="landingPageBannerBlock">
                    <div className="navbarSec" id="ftco-navbar">
                        <div className="container">
                        <a className="navbarBrand" href="index.html"><span>JPL Elite</span> Rentals</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="oi oi-menu"></span> Menu
                        </button>

                        <div className="collapse navbarCollapse" id="ftco-nav">
                            <ul className="navbarNav ml-auto">
                                <li className="navItem active"><a href="index.html" className="navLink">Home</a></li>
                                <li className="navItem"><a href="about.html" className="navLink">About</a></li>
                                <li className="navItem"><a href="services.html" className="navLink">Services</a></li>
                                <li className="navItem"><a href="pricing.html" className="navLink">Pricing</a></li>
                                <li className="navItem"><a href="car.html" className="navLink">Cars</a></li>
                                <li className="navItem"><a href="blog.html" className="navLink">Blog</a></li>
                                <li className="navItem"><a href="contact.html" className="navLink">Contact</a></li>
                                
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="hero-wrap ftco-degree-bg" style={{backgroundImage: "url(" +"images/background/bg_9.jpeg" + ")", 
                    backgroundPosition: '50% 0%'}} data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                    <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
                        <div className="col-lg-8 ftco-animate fadeInUp ftco-animated">
                            <div className="text w-100 text-center mb-md-5 pb-md-5 landingBannerSec">
                                <h1 className="mb-4">Welcome to JPL Elite Rentals a better experience</h1>
                                <div style={{fontSize: '18px',color:'#d2d2d2'}}>Our goal is to connect drivers, travelers, and enthusiasts with quality vehicles at affordable prices.  JPL Elite makes the rental experience modern</div>
                                <div>
                                    {/* <Button className="landingLoginBtn" 
                                        variant="contained" variant="contained" 
                                        color="primary" size="small" 
                                    >Partner Login</Button> */}
                                    <Button className="landingLoginBtn" 
                                        variant="contained" variant="contained" 
                                        color="primary" size="small" 
                                        style={{marginLeft: '12px'}}
                                    >Login</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                       
                    </div>
                </div>
                
                
            </React.Fragment>
        );
    }

}

export default HomeNew;