import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { AiOutlineDashboard } from 'react-icons/ai';
import { MdSettingsSuggest } from 'react-icons/md';
import { GiCarSeat } from 'react-icons/gi';
import { MdOutlineLuggage } from 'react-icons/md';
import { BiGasPump } from 'react-icons/bi';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Grid from '@mui/material/Grid';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


class CarDetails extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            carId: 0,
            //images: [{url:'uploads/cars/car-2.jpg', title:'111'},{url:'uploads/cars/car-2.jpg', title:'222'},{url:'uploads/cars/car-2.jpg', title:'333'}],
            images: [],

            CarDetail: {},
            apiUrl: process.env.REACT_APP_API_URL
        };
        const windowUrl = window.location.search;
        const useQuery = () => new URLSearchParams(windowUrl);
        const query = useQuery();
        const carId = query.get('id');
        this.setState({ carId: carId })
        this.getCarDet(carId);

    }

    componentWillMount() {

    }


    getCarDet = async (carId) => {
        const requestType = 'carDetails';
        fetch(`${this.state.apiUrl}/cars/listCar.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                carId, requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    this.setState({
                        CarDetail: json.data[0],
                        images: json.data[0].images_uploaded
                    });
                } else {
                    this.setState({
                        CarDetail: {},
                        images: []
                    });
                }

            })
    }



    render() {
        const { CarDetail } = this.state;
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section className="hero-wrap hero-wrap-2 js-fullheight" id="backgroundSectionImg" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start" style={{ height: '395px' }}>
                            <div className="col-md-9 ftco-animate pb-5 fadeInUp ftco-animated">
                                <p className="breadcrumbs"><span className="mr-2"><a href="/home">Home <ChevronRightIcon /></a></span> <span>About us </span></p>
                                <h1 className="mb-3 bread">Car Details</h1>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="ftco-section ftco-car-details">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="car-details">
                                    {/* <div className="img" style={{ backgroundImage: 'url(' + "uploads/cars/car-2.jpg" + ')'}}></div> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Carousel autoPlay={true} infiniteLoop={true} swipeable={true}>
                                                {this.state.images.map(({ image_blob, title }, index) => (
                                                    <div>
                                                        <img src={image_blob} />
                                                        {/* <p className="legend">{title}</p> */}
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="text text-center">
                                                <span className="subheading">{CarDetail.make}</span>
                                                <h2>{CarDetail.name}</h2>
                                            </div>


                                            <div className="row">
                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><AiOutlineDashboard className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        KM Driven
                                                                        <span>{CarDetail.km_driven}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><BiGasPump className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        Mileage/Litre
                                                                        <span>{CarDetail.mileage_no}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><MdSettingsSuggest className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        Transmission
                                                                        <span>{CarDetail.transmission}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><GiCarSeat className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        Seats
                                                                        <span>{CarDetail.seats}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><MdOutlineLuggage className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        Luggage
                                                                        <span>{CarDetail.luggage_no} Bags</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md d-flex align-self-stretch ftco-animate fadeInUp ftco-animated">
                                                    <div className="media block-6 services">
                                                        <div className="media-body py-md-4">
                                                            <div className="d-flex mb-3 align-items-center">
                                                                <div className="icon"><BiGasPump className="carDetailsIcons" /></div>
                                                                <div className="text">
                                                                    <h3 className="heading mb-0 pl-3">
                                                                        Fuel
                                                                        <span>{CarDetail.fuel}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <span className="carDetPageBookNowBtn">Book Now</span> 
                                            </div>

                                        </Grid>
                                    </Grid>



                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 pills">
                                <div className="bd-example bd-example-tabs">
                                    <div className="d-flex justify-content-center">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link show active" id="pills-description-tab" data-toggle="pill" href="#pills-description" role="tab" aria-controls="pills-description" aria-expanded="true" aria-selected="true">Features</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link show" id="pills-manufacturer-tab" data-toggle="pill" href="#pills-manufacturer" role="tab" aria-controls="pills-manufacturer" aria-expanded="true" aria-selected="false">Description</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-description" role="tabpanel" aria-labelledby="pills-description-tab">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <ul className="features">
                                                        <li className="check">{CarDetail.airconditions === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Airconditions</li>
                                                        <li className="check">{CarDetail.child_seat === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Child Seat</li>
                                                        <li className="check">{CarDetail.gps === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} GPS</li>
                                                        <li className="check">{CarDetail.luggage === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Luggage</li>
                                                        <li className="check">{CarDetail.music === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Music</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul className="features">
                                                        <li className="check">{CarDetail.seat_belt === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Seat Belt</li>
                                                        <li className="remove">{CarDetail.sleeping_bed === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Sleeping Bed</li>
                                                        <li className="check">{CarDetail.water === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Water</li>
                                                        <li className="check">{CarDetail.bluetooth === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Bluetooth</li>
                                                        <li className="remove">{CarDetail.onboard_computer === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Onboard computer</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul className="features">
                                                        <li className="check">{CarDetail.audio_input === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Audio input</li>
                                                        <li className="check">{CarDetail.long_term_trips === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Long Term Trips</li>
                                                        <li className="check">{CarDetail.car_kit === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Car Kit</li>
                                                        <li className="check">{CarDetail.remote_central_locking === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Remote central locking</li>
                                                        <li className="check">{CarDetail.climate_control === '1' ? <CheckCircleIcon style={{ color: 'green', fontSize: '17px' }} /> : <DangerousIcon style={{ color: 'red', fontSize: '17px' }} />} Climate control</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-manufacturer" role="tabpanel" aria-labelledby="pills-manufacturer-tab">
                                            <p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</p>
                                            <p>When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own road, the Line Lane. Pityful a rethoric question ran over her cheek, then she continued her way.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </React.Fragment>
        );
    }

}

export default CarDetails;