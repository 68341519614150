import React, { Component } from 'react';
//import { Link, Redirect } from 'react-router-dom';

import './assets/css/style.css'
import './assets/css/common.css'
import './assets/css/login.scss'

/* Once the 'Authservice' and 'withAuth' componenets are created, import them into App.js */
import AuthHelperMethods from './components/AuthHelperMethods';
import { Route, Switch } from 'react-router-dom';


//import Navigation from './components/headerMenu/Navigation';

//Our higher order component
import withAuth from './components/withAuth';
import {
  HashRouter //replaced with HashRouter
} from "react-router-dom";
class App extends Component {

  state = {
    username: ''
  }
  /* Create a new instance of the 'AuthHelperMethods' compoenent*/
  Auth = new AuthHelperMethods();

  _handleLogout = () => {
    this.Auth.logout()
    this.props.history.replace('/login');
  }
  

  

  //Render the protected component
  render() {
   // console.log(JSON.parse(localStorage.getItem('user')));
     let userDet = JSON.parse(localStorage.getItem('user'));
    // if (this.props.confirm) {
    //   name = this.props.confirm.username;
    // }
    //let name = this.props.confirm.username;
    return (
      <HashRouter  >
        {/* <div className="header">
          <Header />
        </div> */}
      <div className="bodyContent">
          <Switch>
           {/* <Route exact path={"/profile"} component={Profile} /> */}
           <Route component={Error}/>
         </Switch>
      </div> 
    </HashRouter>
    );
  }
}

//In order for this component to be protected, we must wrap it with what we call a 'Higher Order Component' or HOC.

export default withAuth(App);

