import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

class Home extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
   
    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
       
    }

    render() {
        console.log(this.state.loginError)
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
                    <div className="container">
                        <a className="navbar-brand" href="/home">JPL<span>EXPERIENCE</span></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
                        </button>
                        <div className="collapse navbar-collapse" id="ftco-nav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active"><a href="/home" className="nav-link">Home</a></li>
                                <li className="nav-item"><a href="/about-us" className="nav-link">About</a></li>
                                <li className="nav-item"><a href="pricing.html" className="nav-link">Services</a></li>
                                <li className="nav-item"><a href="car.html" className="nav-link">Our Cars</a></li>
                                <li className="nav-item"><a href="contact.html" className="nav-link">Contact</a></li>
                                <li className="nav-item"><a href="blog.html" className="nav-link">Become a Host</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }

}

export default Home;