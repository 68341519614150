import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

class Footer extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
   
    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
       
    }

    render() {
        console.log(this.state.loginError)
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <footer class="ftco-footer ftco-bg-dark ftco-section">
                    <div class="container">
                        <div class="row mb-5">
                            <div class="col-md">
                                <div class="ftco-footer-widget mb-4">
                                    <h2 class="ftco-heading-2">About JPL Experience</h2>
                                    <p>Our goal is to connect drivers, travelers, and enthusiasts with quality vehicles at affordable prices. JPL Elite makes the rental experience modern.</p>
                                    <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                        <li class="ftco-animate fadeInUp ftco-animated"><a href="#"><span class="icon-twitter"></span></a></li>
                                        <li class="ftco-animate fadeInUp ftco-animated"><a href="#"><span class="icon-facebook"></span></a></li>
                                        <li class="ftco-animate fadeInUp ftco-animated"><a href="#"><span class="icon-instagram"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4 ml-md-5">
                                <h2 class="ftco-heading-2">Information</h2>
                                <ul class="list-unstyled">
                                    <li><a href="#" class="py-2 d-block">About</a></li>
                                    <li><a href="#" class="py-2 d-block">Services</a></li>
                                    <li><a href="#" class="py-2 d-block">Term and Conditions</a></li>
                                    <li><a href="#" class="py-2 d-block">Privacy &amp; Cookies Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Customer Support</h2>
                                <ul class="list-unstyled">
                                    <li><a href="#" class="py-2 d-block">FAQ</a></li>
                                    <li><a href="#" class="py-2 d-block">Payment Option</a></li>
                                    <li><a href="#" class="py-2 d-block">How it works</a></li>
                                    <li><a href="#" class="py-2 d-block">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Have a Questions?</h2>
                                <div class="block-23 mb-3">
                                    <ul>
                                    <li><span class="icon icon-map-marker"></span><span class="text">USA</span></li>
                                    <li><a href="#"><span class="icon icon-phone"></span><span class="text">+1 000 0000 000</span></a></li>
                                    <li><a href="#"><span class="icon icon-envelope"></span><span class="text">info@jplexperience.com</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-12 text-center">
                        <p>
                        Copyright ©<script>document.write(new Date().getFullYear());</script>2022 All rights reserved | This template is developed by <a href="https://juazztech.com" target="_blank">Juazz Technologies</a>
                        </p>
                        </div>
                        </div>
                    </div>
                </footer>
                
            </React.Fragment>
        );
    }

}

export default Footer;